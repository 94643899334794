<template>
  <div class="success-wrap">
    <van-icon name="checked" size="100px" color="#66bb6a" />
    <p>您的融资需求已提交</p>
    <p>请耐心等候融资顾问与您联系</p>
    <!-- <div class="btns">
      <van-button type="info" size="small" @click="goIndex"
        >跳转首页</van-button
      >
    </div> -->
  </div>
</template>

<script>
export default {
  methods: {
    goIndex() {
      this.$router.push("/index");
    }
  }
};
</script>

<style lang="scss" scoped>
.success-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  .van-icon {
    margin-top: 120px;
    margin-bottom: 80px;
  }
  p {
    text-align: center;
    font-size: 28px;
    color: #666;
    line-height: 60px;
  }
  .btns {
    width: 100vw;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 100;
    border-top: 1px solid #efefef;
    height: 112px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 -5px 10px #efefef;
    .van-button {
      width: 90%;
      height: 75%;
    }
  }
}
</style>
